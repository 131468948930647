import { useContext, useEffect, useState } from "react";
import { MyContext } from "../data/contextapi";
import RequestForm from "../../RequestForm";
const doorTypes = {
  Taatsdeur: "TAATSDEUR",
  Schuifdeur: "SCHUIFDEUR",
  Scharnier: "KOZIJN DEUR",
  "vast-stalen": "VAST STALEN",
};

const doorConfigs = {
  enkele: "ENKELE DEUR",
  dubbele: "DUBBELE DEUR",
};

const panels = {
  links: "LINKS",
  rechts: "RECHTS",
  beide: "BEIDE",
  geen: "GEEN",
};

const sidePannelConfigs = {
  "gelijk vardelen": "GELIJKE DELEN",
  "eigen maat": "EIGEN MAAT",
};

const doors = {
  george: "1 paneel",
  "2panel": "2 panelen",
  "3panel": "3 panelen",
  "4panel": "4 panelen",
  "3pannel": "3 panelen ongelijk plus",
  annelot: "Annelot",
  notaris: "Notaris",
  boerderij: "Boerderij",
  herenhuis: "Herenhuis",
  rond: "Rond",
  rondPlus: "Rond Plus",
  "low-deep": "Low Deep",
  rivera: "Rivera",
  porto: "Porto",
  toog: "Toog",
  toogPlus: "Toog plus",
  boender: "Boender",
  contempera: "Contempera",
  fabric: "Fabric",
  dt13: "Hoogh",
  larino: "Larino",
  dt11: "Mexico",
  parallel: "Parallel",
  grandma: "Grandma",
  kasteel: "Kasteel",
  kathedraal: "Kathedraal",
  "the-judge": "The Judge",
  prison: "Prison",
  curved: "Curved",
  lindsey: "Lindsey",
  baku: "Baku",
};

const handles = [
  "Hoeklijn",
  "Beugel",
  "Circle M",
  "Circle L",
  "Koker M",
  "Koker L",
  "Greeploos",
  "Handgreep met leer",
];

const colorGlass = {
  1118481: "Helderglas",
  395529: "Rookglas",
  13467442: "Bronsglas",
  16777215: "Melkglas"
};

const steelColor = {
  "./images/doortypes/RAL 9005 fijn structuur.png": "RAL 9005 fijn structuur",
  "./images/doortypes/RAL 9004 fijn structuur.png": "RAL 9004 fijn structuur",
  "./images/doortypes/RAL 7021 structuur.png": "RAL 7021 structuur",
  "./images/doortypes/RAL 7016 structuur.png": "RAL 7016 structuur",
  "./images/doortypes/RAL 9010 structuur.png": "RAL 9010 structuur",
  "./images/doortypes/RAL 9016 structuur.png": "RAL 9016 structuur",
  "./images/doortypes/Anodic brown.png": "Anodic brown",
  "./images/doortypes/Sterling.png": "Sterling",
  "./images/doortypes/Nobble bronze.png": "Nobble bronze",
  "./images/doortypes/Halo 1036.png": "Halo 1036",
  "./images/doortypes/Halo 1037.png": "Halo 1037",
  "./images/doortypes/Anodic_bronze.png": "Anodic bronze",
};

export default function Information() {
  const {
    step,
    setStep,
    width,
    setWidth,
    height,
    door,
    doorConfig,
    sidePannel,
    type,
    sidePannelConfig,
    sidePannelSize,
    frameSize,
    glassType,
    handle,
    frameType,
    customFrameType,
    extraOptions,
    holeWidth,
    attachDesign,
    compositionImage,
    glContext,
    sceneContext,
    cameraContext
  } = useContext(MyContext);

  const [techInformation, setTechInformation] = useState({
    type: "",
    doorConfig: "",
    sidePannel: "",
    sidePannelConfig: "",
    sidePannelSize: "",
    height: "",
    doorConfig: "",
    width: "",
    door: "",
    frameSize: "",
    handle: "",
    colorGlass: "",
    steelColor: "",
    extraOptions: [],
    compositionImage: compositionImage,
  });

  useEffect(() => {
    setTechInformation({
      type: doorTypes[type],
      doorConfig: doorConfigs[doorConfig],
      sidePannel: panels[sidePannel],
      sidePannelConfig: sidePannelConfigs[sidePannelConfig],
      sidePannelSize,
      height: height,
      width: width,
      holeWidth: holeWidth,
      door: doors[door],
      frameSize:
        frameSize === 0.25 ? "40mm" : frameSize === 0.2 ? "30mm" : "20mm",
      handle: handles[handle - 1],
      colorGlass: colorGlass[glassType],
      steelColor:
        customFrameType.name === ""
          ? steelColor[frameType]
          : customFrameType.name,
      extraOptions,
      compositionImage: compositionImage
    });
  }, [
    type,
    doorConfig,
    sidePannel,
    sidePannelConfig,
    sidePannelSize,
    height,
    doorConfig,
    width,
    door,
    holeWidth,
    frameSize,
    handle,
    glassType,
    steelColor,
    extraOptions,
    customFrameType,
    compositionImage
  ]);

  return (
    <>
      <RequestForm
        techInformation={techInformation}
        attachDesign={attachDesign}
        glContext={glContext}
        sceneContext={sceneContext}
        cameraContext={cameraContext}
      />
    </>
  );
}
